export enum CustomerGroupId {
  DENTIST = 'd0d0a44352224a9db0a96e82425f4261',
  SURGERY = '362ea99899774a019afe13cd00216b7d',
  PHARMACY = 'b74c2301460d44ce97a145efd7f9770b',
  COMPANY = '1c6c8063b5094e31964eae1ae3a7eeee',
  STUDENT = 'd473329bcb3c4e4da0ca64ff71206b1d',
  OTHER = '45bf33cca283489f8fcc5149a10ebbdc',
  DENTAL_LAB = '4e02d4476e294952a617e5c4b0e4441e',
  PRACTICE_LAB = 'e22f1d8336764e3684875c0e24c772b4',
  DENTIST_WITHOUT_VERIFICATION = '018b239808a670acaddab0e7b4e562cc',
  PHARMACY_WITHOUT_VERIFICATION = '018b239808a670acaddab0e7b585d775',
  SURGERY_WITHOUT_VERIFICATION = '018d9d6bc4ea7a738e6a1a8c88e527a7',
  VET_WITHOUT_VERIFICATION = 'f86488b6e693438c8506117f05513f50',
  VET = '5e1252c64e144a64857f2b218128ea72',
}

export const CustomerGroupsWithoutVerification = [
  CustomerGroupId.DENTIST_WITHOUT_VERIFICATION,
  CustomerGroupId.PHARMACY_WITHOUT_VERIFICATION,
  CustomerGroupId.SURGERY_WITHOUT_VERIFICATION,
  CustomerGroupId.VET_WITHOUT_VERIFICATION,
];
